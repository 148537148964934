import React from "react"
import "./Loader.css"

import SEO from "../seo"

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <SEO title="Loading" />
      <div className="loader-balls">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
