import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinksWrapper>
        <FooterDesc>
          <h1>UltraDevX</h1>
          <p>Master coding with ease.</p>
        </FooterDesc>
        <FooterLinkItems>
          <FooterLinkTitle>Links</FooterLinkTitle>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/services">Services</FooterLink>
          <FooterLink to="/about">About</FooterLink>
          <FooterLink to="/videos">Videos</FooterLink>
          <FooterLink to="/contact">Contact</FooterLink>
        </FooterLinkItems>
      </FooterLinksWrapper>
      <FooterLinksWrapper>
        <FooterLinkItems>
          <FooterLinkTitle>Videos</FooterLinkTitle>
          <FooterALink
            href="https://www.youtube.com/watch?v=EEudyBMW2WU"
            target="_blank"
          >
            HTML Forms
          </FooterALink>
          <FooterALink
            href="https://www.youtube.com/watch?v=85XgDNk2GIs&t=385s"
            target="_blank"
          >
            Styling Forms
          </FooterALink>
          <FooterALink
            href="https://www.youtube.com/watch?v=xNI8RY8Egpg&t=10s"
            target="_blank"
          >
            HTML Images
          </FooterALink>
          <FooterALink
            href="https://www.youtube.com/watch?v=IgMxw60buB0&t=63s"
            target="_blank"
          >
            Color
          </FooterALink>
          <FooterALink
            href="https://www.youtube.com/watch?v=3nl0C2tuLvw&t=7s"
            target="_blank"
          >
            Text
          </FooterALink>
        </FooterLinkItems>
        <FooterLinkItems>
          <FooterLinkTitle>Social Media</FooterLinkTitle>
          <FooterALink href="https://www.youtube.com/channel/UC_HURE4AcjkGbV2xgHrtD8A?sub_confirmation=1">
            Youtube
          </FooterALink>
        </FooterLinkItems>
      </FooterLinksWrapper>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #000;
  background: #fafafb;
`

const FooterDesc = styled.div`
  padding: 0 2rem;

  h1 {
    margin-bottom: 3rem;
    color: #4654a3;
  }

  p {
    font-size: 18px;
  }

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinkTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 16px;
`

const FooterLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  color: #3d3d4f;

  &:hover {
    color: #4654a3;
    transition: 0.3s ease-out;
  }
`

const FooterALink = styled.a`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  color: #3d3d4f;

  &:hover {
    color: #4654a3;
    transition: 0.3s ease-out;
  }
`
