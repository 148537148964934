import React, { useEffect, useState } from "react"
import Footer from "./Footer/Footer"
import Header from "./Header"
import Loader from "./Loader/Loader"
import { GlobalStyle } from "./styles/GlobalStyles"

const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  })

  return (
    <>
      {loading === true && <Loader />}
      {loading === false && (
        <div className="page">
          <GlobalStyle />
          <Header />
          <main>{children}</main>
          <Footer />
        </div>
      )}
    </>
  )
}

export default Layout
