import { Link } from "gatsby"
import styled from "styled-components"

export const Button = styled(Link)`
  background: ${({ primary }) => (primary ? "#1a58eb" : "#000077")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "16px 40px" : "10px 32px")};
  color: #fff;
  font-size: ${({ big }) => (big ? "20px" : "16px")};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ round }) => (round ? "50px" : "none")};

  &:hover {
    background: ${({ primary }) => (primary ? "#000077" : "#1a58eb")};
    transform: translateY(-2px);
  }
`

export const ABtn = styled.a`
  background: ${({ primaryA }) => (primaryA ? "#1a58eb" : "#000077")};
  white-space: nowrap;
  padding: ${({ bigA }) => (bigA ? "16px 40px" : "10px 32px")};
  color: #fff;
  font-size: ${({ bigA }) => (bigA ? "20px" : "16px")};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ roundA }) => (roundA ? "50px" : "none")};

  &:hover {
    background: ${({ primaryA }) => (primaryA ? "#000077" : "#1a58eb")};
    transform: translateY(-2px);
  }
`
